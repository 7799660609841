<template>
  <div class="container-fixed">
    <div class="intro-box">
      <div>
        <div class="animate__animated animate__fadeInUp">
          <div class="intro-box-title">What We Do</div>
          <hr />
          <div class="intro-box-sub">
            텍스트리는 LG, 삼성, 두산, 현대, 지멘스, 등과 같은 글로벌 기업이 선택한 신뢰할 수 있는 기술 컨텐츠 파트너입니다. 우리는 업계 최고가 되기 위해 계속해서 서비스를 발전시키고 우리 자신에 도전하고 있습니다.
            <br>
            <br>
            고객사의 즉각적이고 장기적인 요구 사항을 파악하고 비즈니스 요구 사항에 맞는 계획을 개발하기 위해 노력하여 기술문서 제작 및 관리에 대한 솔루션을 보유하고 있다는 확신을 드립니다.
          </div>
        </div>
      </div>
      <div class="proj-menu-content">
        <div>
          <div class="proj-menu-link animate__animated animate__fadeInDown">
            <a v-for="client in currentClients" :key="client.id" :href="client.id > 0 ? '/portfolio/' + client.id : 'javascript:void(0)'">
              <span :style="{ backgroundImage: 'url(' + client.logoFiles[0].thumb_url + ')' }"
                @mouseenter="previewClientImage({ $event, client })"
                @mouseleave="previewClientLogo({ $event, client })">
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import api from '@/api'

export default {
  data: () => ({
    selectedCategoryID: 0,
    currentPage: 1
  }),
  computed: {
    clients: function () {
      var clients = []
      for (var client of store.state.portfolio.clients) {
        if (this.selectedCategoryID == 0) {
          clients.push(client)
        }
      }
      return clients
    },
    currentClients: function () {
      var last = this.currentPage * 9
      var start = last - 9
      var end = Math.min(this.clients.length, last)
      var clients = this.clients.slice(start, end)
      var clientCtr = 1;
      while (clients.length < 9) {
        clients.push({
          id: clientCtr,
          logoFiles: [{ thumb_url: '/assets/images/blank.jpg' }],
          image_thumb_url: '/assets/images/blank.jpg'
        })
        clientCtr++
      }
      return clients
    }
  },
  mounted() {
    api.portfolio.getClients()
    store.commit('SET_NAV_THEME', 'light')
    store.commit('SET_MORE_THEME', 'light')
    store.commit('SET_MOBILE_MORE_FILL', false)
  },
  methods: {
    previewClientLogo: function (e) {
      e.$event.target.style.backgroundImage = 'url(' + e.client.logoFiles[0].thumb_url + ')';
      e.$event.target.style.backgroundSize = '80%';
    },
    previewClientImage: function (e) {
      e.$event.target.style.backgroundImage = 'url(' + e.client.image_thumb_url + ')';
      e.$event.target.style.backgroundSize = 'cover';
    }
  }
}
</script>

<style scoped>
.container-fixed {
  background-image: url('/assets/images/homepage/home_company.jpg') !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}

.intro-box {
  color: rgba(255, 255, 255, .35);
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
  border-left: 1px solid;
  border-right: 1px solid;
  display: flex;
}

.intro-box>div {
  margin-top: 0;
  width: 37.5%;
  height: 100%;
  border-right: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.intro-box-title {
  color: rgba(255, 255, 255, 1);
  padding: 24px;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 64px;
  line-height: 75px;
  margin-top: 3em;
}

.intro-box-sub {
  color: rgba(255, 255, 255, 1);
  padding: 24px;
  font-family: "Nanum Gothic";
  font-size: 16px;
  line-height: 50px;
}

hr {
  width: 100px;
  background: #F1B500;
  height: 5px;
  border: none;
  margin-left: 24px;
  margin-top: 3em;
  margin-bottom: 4em;
}

.link-title {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: bold;
}

.link-desc {
  font-family: "Nanum Gothic";
  font-size: 16px;
  font-weight: normal;
}


div.proj-menu-content {
  display: flex;
  width: calc(100% - 37.5%);
  padding-left: 24px;
  padding-right: 24px;
}
div.proj-menu-content > div:not(.proj-menu-definition) {
  flex: 0 0 60%;
}
div.proj-menu-link {
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0;
  background: white;
  border-radius: 5px;
  padding: 12px;
}
div.proj-menu-link a {
  flex: 0 0 calc(33%);
  height: calc(200px + 2em);
  border: 1px solid #D9D9D9;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.proj-menu-link a span {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
}

div.proj-menu-definition {
  margin-top: 24px;
  padding: 0 20px;
}
div.proj-menu-definition h2 {
  font-family: 'Montserrat';
  color: #008ED6;
  font-size: 45px;
  margin-bottom: 0;
}
div.divider {
  width: 100px;
  margin: 36px 0;
}
div.proj-menu-definition hr {
  background: #F1B500;
  height: 5px;
  border: none;
  width: 100%;
  margin-right: auto;
}
div.proj-menu-definition .content {
  font-family: 'Nanum Gothic';
  font-size: 18px;
  line-height: 2.25;
}

@media(max-width: 1280px) {
  .intro-box-title {
    margin-top: 1em;
    font-size: 48px;
    line-height: 1.3;
  }

  hr {
    margin: 0 24px;
  }

  .intro-box-sub {
    line-height: 2;
  }
}

@media(max-width: 768px) {
  .intro-box-title {
    font-size: 24px;
  }

  .intro-box>div {
    width: 100%;
  }
}

@media(max-width: 425px) {
  .container-fixed {
    background-position: 64%;
  }

  .intro-box-title {
    color: rgba(255, 255, 255, 1);
    padding: 24px;
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 35px;
    line-height: 75px;
    margin-top: 3em;
  }

  .intro-box-sub {
    color: rgba(255, 255, 255, 1);
    padding: 24px;
    font-family: "Nanum Gothic";
    font-size: 12px;
    line-height: 25px;
  }
}

@media(max-height: 680px) {
  .intro-box-title {
    font-size: 24px;
    line-height: 36px;
    padding: 12px 24px;
  }
}</style>
